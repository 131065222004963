import moment, { Moment } from 'moment-timezone';
import {
  camelCase,
  get,
  isUndefined,
  isNull,
  isArray,
  isPlainObject,
  mapValues,
  map,
  mapKeys,
  memoize,
  snakeCase,
  some,
} from 'lodash';
import { toDate } from 'date-fns-tz';
import { useEffect, useRef } from 'react';

import config from 'config';
import currencyMap from 'lib/currencyMap';
import { dateFormatsDeprecated, PERCENT } from 'lib/constants';

export type Opaque<K, T> = T & { __TYPE__: K };

export type TransformJsonOptions = Partial<{ excludeRegexes?: Array<RegExp> }>;

// https://gist.github.com/jlbruno/1535691
export const getOrdinal = (num: number): string => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const val = Math.abs(num) % 100;
  return `${num}${suffixes[(val - 20) % 10] || suffixes[val] || suffixes[0]}`;
};

type DateArgs = {
  format?: string;
  showToday?: boolean;
  hideCurrentYear?: boolean;
  zone?: string;
};

export function standardFormatDate(
  date: Date | Moment | string | number | undefined,
  { format = 'short', showToday = true, hideCurrentYear = false, zone }: DateArgs = {}
): string | false {
  if (!date) return false;
  if (showToday) {
    const momentByZone = zone ? moment().tz(zone) : moment().tz(moment.tz.guess());
    if (momentByZone.isSame(date, 'day')) {
      return 'Today';
    }

    if (momentByZone.clone().add(1, 'day').isSame(date, 'day')) {
      return 'Tomorrow';
    }

    if (momentByZone.clone().subtract(1, 'day').isSame(date, 'day')) {
      return 'Yesterday';
    }
  }

  if (!hideCurrentYear) {
    return moment(date).format(dateFormatsDeprecated[`${format}_with_year`]);
  }

  return moment(date).format(dateFormatsDeprecated[format]);
}

export function debounce(
  fn: Function,
  delay: number
): (event: React.SyntheticEvent<any> | any) => void {
  let timer; // eslint-disable-line fp/no-let

  return (event: React.SyntheticEvent<any> | any) => {
    const value = get(event, 'currentTarget.value', event);

    clearTimeout(timer);
    timer = setTimeout(() => fn(value), delay);
  };
}

export function toSentence(arr: Array<string>, separator = ', ', terminal = ' and '): string {
  if (arr.length === 0) {
    return '';
  }

  if (arr.length === 1) {
    return arr[0];
  }
  return arr.slice(0, arr.length - 1).join(separator) + terminal + arr.slice(-1).toString();
}

/**
 * @deprecated Avoid using this function. Use native functions instead.
 * To check if something is not null or undefined use `!value`.
 * Use `Array.isArray` and `value.length` to check for empty arrays.
 * You may use `isEmpty` from Lodash to check if something is an empty object, but it's advised to avoid empty objects.
 *
 * Rationale:
 * First and foremost, this function was supposed to emulate the behavior of `#present?` from Ruby on Rails, which does not match the
 * practices of coding Javascript.
 * There is a distinction in Javascript between null and undefined; null states "the value is nothing" while undefined
 * means "no value" or no presence of a value. (https://stackoverflow.com/a/5076962/242826, https://stackoverflow.com/a/57249968/242826).
 *
 * In addition, there should be clear distinction between an empty object and null. Previously, we used an empty object
 * as a "lazy" way of preventing potential errors ("TypeError: Cannot read property 'foo' of null/undefined").
 * Now that we have Typescript and its `?.` operator, this is no longer necessary, and is actually detrimental to types.
 * For example, forcing us to turn an object type "Foo" into "Partial<Foo>".
 *
 * And finally, it's advised to always prefer an array over an array/null/undefined combination.
 * Since arrays may always be empty, they already have a "null" or "empty" representation, which is why they make null
 * values redundant.
 *
 * In summary, objects whould be declared as nullable by using "Obj | null", while arrays should always be declared as
 * "Array<...>" (and not "Array<...> | null").
 * You may include "Obj | null | undefined", but be aware of the implication and semantic meaning.
 *
 * Due to these differences, and the fact that Typescript cannot infer the meaning of this function, it's better to use
 * the tools provided by Javascript as mentioned in the beginning and keep a separation between checking
 * whether an object is present and whether an array is not empty.
 *
 * @returns true if the object is defined and not null, and not empty if it's an object, string, or array
 */
export function deprecated_isPresent(obj: any): boolean {
  // We can use `some` here because a single object that is not null or undefined will return true,
  // and for an empty string, array, or object it will return false, which is what we want.
  return !(isUndefined(obj) || isNull(obj) || !some(obj));
}

export function addOrdinalSuffix(number?: number): string {
  if (typeof number === 'undefined') return '';

  // http://stackoverflow.com/a/31615643
  const suffix = ['th', 'st', 'nd', 'rd'];
  const num = number % 100;
  return number + (suffix[(num - 20) % 10] || suffix[num] || suffix[0]);
}

export function addSpacesToTitleCase(titleCaseString: string): string {
  // http://stackoverflow.com/a/15370765/3875489
  const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;

  return titleCaseString.replace(regex, '$1$4 $2$3$5');
}

export function isFirstOfMonth(date: string | Date): boolean {
  return moment(date).toDate().getDate() === 1;
}

export function isMidMonth(date: Date | string): boolean {
  return !isFirstOfMonth(date);
}

export function lastDayOfCurrentMonth(): Moment {
  return moment().endOf('month');
}

export function lastDayOfNextMonth(): Moment {
  return moment().add(1, 'month').endOf('month');
}

export const getSpacemanAccountUrl = (accountShortCode: string, locationCode: string): string => {
  const url = `${config.spaceman.uri}/admin/accounting/accounts/${accountShortCode}`;

  if (!locationCode) {
    return url;
  }

  return `${url}?location_id=${locationCode}`;
};

const matchAnyRegex = (value: string, regexes: Array<RegExp>): boolean =>
  regexes.some(regex => regex.test(value));

const transformJson = (transformer: Function) =>
  function recursivelyTransform(obj: any): any {
    // eslint-disable-next-line no-nested-ternary
    return isArray(obj)
      ? map(obj as Array<unknown>, (val: unknown) => recursivelyTransform(val))
      : isPlainObject(obj)
      ? mapValues(
          mapKeys(obj as {}, (_, key: string) => transformer(key)),
          (val: unknown) => recursivelyTransform(val)
        )
      : obj;
  };

export const camelCaseJson = transformJson(camelCase);

export const snakeCaseJson = transformJson(snakeCase);

export const jsonTransformExtended = (
  transformer: (arg0: string) => string,
  options: TransformJsonOptions = {}
) => {
  const { excludeRegexes } = options;
  let transformerFn = transformer;

  if (excludeRegexes) {
    transformerFn = key => (matchAnyRegex(key, excludeRegexes) ? key : transformer(key));
  }

  return transformJson(transformerFn);
};

export const camelCaseJsonExtended = (options: TransformJsonOptions) =>
  jsonTransformExtended(camelCase, options);

export const snakeCaseJsonExtended = (options: TransformJsonOptions) =>
  jsonTransformExtended(snakeCase, options);

// Return the currency symbol for the given currency.
// NOTE: Do not use this method directly for displaying prices! If you need to
// display a price, use getLocalizedPrice() below.
// @param currencyCode {String} the three letter ISO 4217 currency code, e.g. USD
// @return {String} the currency symbol, e.g $.
export const getCurrencySymbol = (currencyCode: string | undefined): string | never => {
  if (!currencyCode) {
    throw new Error('currency is required');
  }
  return currencyMap[currencyCode] ? currencyMap[currencyCode] : currencyCode;
};

// Localize the price for the locale and currency code.
// @param price {Number} the price
// @param currencyCode {String} the three letter ISO 4217 currency code, e.g. USD
// @param locale {String} the locale, defaults to 'en-US'
// @return {String} the localized price.
export const getLocalizedPrice = ({
  price,
  currencyCode,
  locale = 'en-US',
}: {
  price: number | string;
  currencyCode?: string | null | undefined;
  locale?: string;
}): string => {
  const currency = currencyCode ?? 'USD';

  // Since Intl does not have all currency symbols, we format using the code,
  // then replace the code with the currency symbol taken from lib/currencyMap.
  // We append '-u-nu-latn' to use Latin numbers so all speakers can read them.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
  const localeWithLatin = locale.concat('-u-nu-latn');

  const str = new Intl.NumberFormat(localeWithLatin, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
  }).format(Number(price));

  const currencySymbol = getCurrencySymbol(currency);

  return str.replace(currency, currencySymbol);
};

export const isNumber = (value: any): boolean => !isNaN(parseFloat(value)) && isFinite(value);

export const hyphenDelimitString = (value: string): string => {
  return value.replace(/\W+/g, '-').toLowerCase();
};

export const getMimoFromOccupancy = (occupancy: {
  reservations: Array<{
    started_on: string;
    ended_on: string;
  }>;
}): boolean => {
  const mi = moment().add(1, 'month').startOf('month').format(dateFormatsDeprecated.iso_date);
  const mo = moment().endOf('month').format(dateFormatsDeprecated.iso_date);

  return some(occupancy.reservations, reservation => {
    return reservation.started_on === mi || reservation.ended_on === mo;
  });
};

export const getClosest = (
  elem: Element | null | undefined,
  selector: string | Element
): Element | null | undefined => {
  if (!elem) {
    return null;
  }

  let iter: Element | null | undefined = elem;

  for (; iter; iter = iter.parentElement) {
    if (
      (typeof selector === 'string' && iter.matches(selector)) ||
      (iter === selector && iter instanceof HTMLElement)
    ) {
      return elem;
    }
  }

  return null;
};

// Discounts and commitment based offerings are grouped based on the location
// and start date of the reservations to which they are applied. This is
// accomplished through a dictionary where they key is a string of the form
// '<location> - <startDate>'
export const getLocationAndStartDate = (reservation: {
  building: string;
  buildingName: string;
  moveIn: string;
  start_date: string;
}): string => {
  const building = reservation.building || reservation.buildingName;
  const startDate = reservation.moveIn || reservation.start_date;
  return `${building} - ${startDate}`;
};

export function getFromStorage<T extends {}>(_storage: Storage, key: string): T | null {
  try {
    const value = sessionStorage.getItem(key);

    return value ? (JSON.parse(value) as T) : null;
  } catch (err) {
    return null;
  }
}

export const multilineToSingleLine = (str: string | null | undefined) =>
  String(str || '')
    .replace(/(\r\n|\n\r)/g, ' ')
    .replace(/[\n\t]/g, ' ');

export const copyTextToClipboard = (text: string) => navigator.clipboard.writeText(text);

export const isValidDate = date => !isNaN(date?.getTime());

export function parseDateInUTC(date: string | Date): Date {
  const dateObj = toDate(date);
  return toDate(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface PromiseResolvedResult<T> {
  status: 'fulfilled';
  value: T;
}

interface PromiseRejectedResult {
  status: 'rejected';
  reason: any;
}

type PromiseSettledResult<T> = PromiseResolvedResult<T> | PromiseRejectedResult;

export type PromiseSettledResults<T> = PromiseSettledResult<T>[];

export type PromiseWithAllSettled = PromiseConstructor & {
  allSettled<T>(values: Iterable<T | PromiseLike<T>>): Promise<PromiseSettledResults<T>>;
};

const memoizedIntlNumberFormatCurrency = memoize(
  options => new Intl.NumberFormat('en-US', { style: 'currency', ...options })
);

export const getFormattedCurrency = (
  price: number | string,
  currency: string = 'USD',
  options = {}
) => memoizedIntlNumberFormatCurrency({ currency, ...options }).format(Number(price));

const memoizedIntlNumberFormat = memoize(() => new Intl.NumberFormat('en-US'));

export const getFormattedNumber = (number: number) => memoizedIntlNumberFormat().format(number);

export const snakeCaseToWords = (string: string) => {
  return string.replace(/_/g, ' ');
};

const ANONYMIZED_PHONE = '+19999999999';

// TODO: Retire this workaround; either provide normalized_phone from
// Account Service too or just format phone uniformly on the backend.
export const getNormalizedPhone = ({
  phone,
  normalized_phone,
}: {
  phone?: string;
  normalized_phone?: string;
}) => (normalized_phone && normalized_phone !== ANONYMIZED_PHONE ? normalized_phone : phone);

export const getLimitedLengthStringList = ({
  maxChars,
  strings,
}: {
  maxChars: number;
  strings: string[];
}): {
  displayText: string;
  fullText: string;
} => {
  const sortedStrings = strings.sort((string1, string2) => string1.length - string2.length);
  const totalStrings = sortedStrings.length;
  const fullText = sortedStrings.join(', ');

  let displayText = '';
  for (let i = 0; i < totalStrings; i++) {
    const currentString = sortedStrings[i];

    if (i === 0 && currentString.length > maxChars) {
      const numRemaining = totalStrings - 1;
      const charsToDisplay = numRemaining ? maxChars - 5 : maxChars - 2;
      const numRemainingDisplay = numRemaining ? ` +${numRemaining}` : '';
      return {
        displayText: `${currentString.substring(0, charsToDisplay)}...${numRemainingDisplay}`,
        fullText,
      };
    }

    if (currentString.length + displayText.length > maxChars) {
      const numRemaining = totalStrings - i;
      return {
        displayText: `${displayText} +${numRemaining}`,
        fullText,
      };
    }

    displayText += i === 0 ? currentString : `, ${currentString}`;
  }

  return {
    displayText,
    fullText,
  };
};

/**
 * Replace seemingly innocuous % with uri encoding so it doesn't break decoding
 * @param {String} textToConvert The string to replace text inside of
 * @return {String}
 */
export function replaceRTEPercent(textToConvert?: string): string {
  const decodeRegExp = new RegExp(PERCENT.DECODED, 'g');
  return textToConvert?.replace(decodeRegExp, PERCENT.ENCODED) ?? '';
}

export const getKubeUrl = (franchise?: string | null) => {
  let kubeBaseUrl = config.kube.uri;
  if (!franchise) return kubeBaseUrl;
  switch (franchise?.toLocaleLowerCase()) {
    case 'india':
      kubeBaseUrl = config.kube.franchiseUri.india;
      break;
    case 'japan':
      kubeBaseUrl = config.kube.franchiseUri.japan;
      break;
    default:
      kubeBaseUrl = config.kube.uri;
      break;
  }
  return kubeBaseUrl;
};
