import { isEmpty } from 'lodash';

import isInIframe from '../actions/isInIframe';
import { getActions } from '../actions/defaultActions';
import { getActions as iframeGetActions } from '../actions/iframeActions';
import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';

export const getActionsPayload = (inventory: InventoryPayloadItem) => {
  const available = inventory.available;
  const hold = inventory.hold;
  const locationUuid = inventory.location?.uuid;
  const locationCode = inventory.location?.code;
  const name = inventory.name;
  const notes = inventory.notes;
  const onHold = inventory.onHold;
  const paperworkPending = inventory.paperworkPending;
  const type = inventory.type;
  const uuid = inventory.uuid;
  const partialTimeOffice = inventory.inventoryTags?.find(tag => tag.name === 'Part Time Office');
  const isLocationMigrated = !!inventory.location?.isMigrated;
  const franchise = inventory.location?.franchise;

  return {
    available,
    hold,
    locationUuid,
    locationCode,
    name,
    notes,
    onHold,
    paperworkPending,
    type,
    uuid,
    partialTimeOffice,
    isLocationMigrated,
    franchise,
  };
};

export default (inventory: InventoryPayloadItem): InventoryAttribute | null | undefined => {
  const payload = getActionsPayload(inventory);

  const actions = isInIframe() ? iframeGetActions(payload) : getActions(payload);

  if (!actions || isEmpty(actions)) {
    return null;
  }

  return {
    value: '',
    meta: { actions, payload },
  };
};
