import rollbar from 'lib/rollbar';
import { Location as SILocation } from 'store/modules/siLocations';
import { getSpacemanCode, getSpacemanId } from 'store/modules/siLocations/utils';
import { InventoryItem as SIInventoryItem } from 'store/modules/siInventory/types';

import {
  InventoryAttribute,
  InventoryItem,
  InventoryPayload,
  InventorySearchResult,
  SearchResultPayload,
  SerializerConfig,
  SIInventorySearchResult,
  SIInventoryAndLocationPreprocess,
} from '../types';
import {
  InventoryPayloadItem,
  Location as InventoryLocation,
  Hold,
  GeoLocation,
} from '../config/types';

export const serializeInventoryItem = (
  inventoryPayloadItem: InventoryPayloadItem,
  serializersMap: Hash<SerializerConfig>
): InventoryItem => {
  const uuid = inventoryPayloadItem?.uuid;

  const attributeEntries = Object.entries(serializersMap).reduce(
    (
      result: Array<[string, InventoryAttribute]>,
      [key, serializerConfig]: [string, SerializerConfig]
    ): Array<[string, InventoryAttribute]> => {
      const { serializer, serializerKey } = serializerConfig;
      const serializedPayload = serializer(inventoryPayloadItem, serializerKey);

      if (serializedPayload) {
        result.push([key, serializedPayload]);
      }

      return result;
    },
    []
  );

  return {
    uuid,
    attributes: Object.fromEntries(attributeEntries),
  };
};

export const getSerializedInventoryItems = (
  rawPayload: InventoryPayload,
  serializersMap: Hash<SerializerConfig>,
  serializeItem = serializeInventoryItem
): Array<InventoryItem> => {
  if (!rawPayload) {
    return [];
  }

  return rawPayload.reduce(
    (resultList: Array<InventoryItem>, inventoryPayloadItem): Array<InventoryItem> => {
      try {
        resultList.push(serializeItem(inventoryPayloadItem, serializersMap));
      } catch (err) {
        rollbar.error(
          err.message,
          err,
          `[IQS] Something when wrong serializing ${inventoryPayloadItem.toString()}`
        );
      }

      return resultList;
    },
    []
  );
};

// TODO: remove once direct IQS query can be removed
export const serializeSearchResult = (
  rawPayload: SearchResultPayload,
  serializersMap: Hash<SerializerConfig>
): InventorySearchResult => {
  const result: InventorySearchResult = {
    inventory: [],
    inventoryGroups: [],
    metadata: {
      location: {
        availableCount: {},
      },
      pagination: {
        count: 0,
        countPerPage: 0,
        currentPageNumber: 0,
        totalPages: 0,
      },
    },
    rawPayload: {
      inventory: [],
    },
  };

  if (rawPayload) {
    const { inventory, inventoryGroups, metadata } = rawPayload;

    result.rawPayload = rawPayload;

    if (inventory) {
      result.inventory = getSerializedInventoryItems(inventory, serializersMap);
    }

    if (inventoryGroups) {
      result.inventoryGroups = getSerializedInventoryItems(inventoryGroups, serializersMap);
    }

    if (metadata) {
      if (metadata?.locationMetadata?.availableCount) {
        result.metadata.location = {
          availableCount: metadata.locationMetadata.availableCount,
        };
      }

      if (metadata.paginationMetadata) {
        const { paginationMetadata } = metadata;
        const { pagination } = result.metadata;
        pagination.count = paginationMetadata?.count || 0;
        pagination.countPerPage = paginationMetadata?.countPerPage || 0;
        pagination.currentPageNumber = paginationMetadata?.page || 0;
        pagination.totalPages = paginationMetadata?.totalPages || 0;
      }
    }
  }

  return result;
};

export interface ConformedSIInventorySearchResultPayload {
  inventory: Array<InventoryPayloadItem>;
}

// combines the raw inventory response with the raw location response into a single standard inventory item format
const conformSIInventory = (
  inventoryItem: SIInventoryItem,
  locationMapping: Hash<SILocation>
): InventoryPayloadItem => {
  let conformedGeoLocation: GeoLocation | undefined;
  const conformedLocation: InventoryLocation = {};

  if (inventoryItem.locationId?.value && locationMapping[inventoryItem.locationId.value]) {
    const siLocation = locationMapping[inventoryItem.locationId.value];
    const address = siLocation.defaultAddress;

    conformedLocation.uuid = inventoryItem.locationId.value;
    conformedLocation.code = getSpacemanCode(siLocation);
    conformedLocation.name = siLocation.name;
    conformedLocation.address1 = address?.line1;
    conformedLocation.city = address?.locality;
    conformedLocation.country = address?.country;
    conformedLocation.isMigrated = siLocation.isMigrated;
    conformedLocation.buildings = siLocation.buildings;
    conformedLocation.franchise = siLocation.franchise;

    if (address?.latitude && address?.longitude) {
      conformedGeoLocation = {
        latitude: address.latitude,
        longitude: address.longitude,
      };
    }
  }

  let floor;
  if (inventoryItem.floorName) {
    floor = {
      name: inventoryItem.floorName,
    };
  }

  let hold: Hold | undefined;
  if (inventoryItem.hold) {
    hold = {
      uuid: inventoryItem.hold.id,
      active: inventoryItem.hold.isActive,
      begins_at: inventoryItem.hold.beginsAt,
      ends_at: inventoryItem.hold.endsAt,
    };
  }

  return {
    uuid: inventoryItem.id,
    name: inventoryItem.name,
    capacity: inventoryItem.capacity,
    archivedDate: inventoryItem.archivedDate,
    buildingClass: inventoryItem.buildingClass,
    buildingTier: inventoryItem.buildingTier,
    filterableGate:
      inventoryItem.filterableGate && inventoryItem.filterableGate === 'OPEN'
        ? 'Open'
        : inventoryItem.filterableGate,
    hasWindow: inventoryItem.hasWindow,
    type: inventoryItem.productType,
    workUnits: inventoryItem.workUnits,
    spatialWorkUnits: inventoryItem.spatialWorkUnits,
    availability: inventoryItem.availability,
    internalRoomCount: inventoryItem.internalRoomCount,
    notes: inventoryItem.notes,
    openDate: inventoryItem.openDate,
    price: inventoryItem.price,
    squareFootage: inventoryItem.squareFootage,
    usableSquareFootage: inventoryItem.usableSquareFootage,
    rentableSquareFootage: inventoryItem.rentableSquareFootage,
    seatCount: inventoryItem.seatCount,
    displayType: inventoryItem.displayProductType,
    floor,
    location: conformedLocation,
    startDate: inventoryItem.startDate,
    available: inventoryItem.isAvailable,
    onHold: inventoryItem.isOnHold,
    paperworkPending: inventoryItem.isPaperworkPending,
    hold,
    currentMoveIn: inventoryItem.currentMoveIn,
    currentMoveOut: inventoryItem.currentMoveOut,
    nextMoveIn: inventoryItem.nextMoveIn,
    geoLocation: conformedGeoLocation,
    stargateFloorUuid: inventoryItem.floorId,
    inventoryTags: inventoryItem.tags,
  };
};

export const conformAllSIResponses = (
  rawPayload: SIInventoryAndLocationPreprocess
): ConformedSIInventorySearchResultPayload => {
  const inventoryData: SIInventoryItem[] = rawPayload.inventory.data;
  const locationMapping: Hash<SILocation> = rawPayload.locations.reduce((mapping, location) => {
    const newMapping = {
      ...mapping,
    };
    newMapping[getSpacemanId(location)] = location;
    return newMapping;
  }, {});

  const conformedInventory: InventoryPayloadItem[] = inventoryData.map(inv =>
    conformSIInventory(inv, locationMapping)
  );
  const searchResultPayload: ConformedSIInventorySearchResultPayload = {
    inventory: conformedInventory,
  };

  return searchResultPayload;
};

export const serializeSearchResultSI = (
  rawPayload: SIInventoryAndLocationPreprocess,
  serializersMap: Hash<SerializerConfig>
): SIInventorySearchResult => {
  const result: SIInventorySearchResult = {
    inventory: [],
    metadata: {
      pagination: {
        offset: 0,
        limit: 100,
        totalCount: 0,
      },
      location: {
        availableCount: {},
      },
    },
    rawPayload: rawPayload.inventory,
    isSearchGroups: rawPayload.isSearchGroups,
  };
  const conformed = conformAllSIResponses(rawPayload);
  result.inventory = getSerializedInventoryItems(conformed.inventory, serializersMap);

  result.metadata.pagination = rawPayload.inventory.pageInfo;

  const availableCount = {};
  rawPayload.inventory.meta.availableCount.forEach(tuple => {
    availableCount[tuple.locationId] = tuple.count;
  });

  result.metadata.location.availableCount = availableCount;
  return result;
};
